import React, { useEffect, useState, useContext } from "react";
import PageHeader from "../components/PageHeader";
import { ThemeContext } from "../layout/Layout";
import EscapeLogo from "../images/escape.svg";

import { RadioGroup } from "@headlessui/react";
import InnerHeadr from "../components/InnerHeader";

const plans = [
  { name: "Hobby", ram: "8GB", cpus: "4 CPUs", disk: "160 GB SSD disk", price: "$40" },
  { name: "Startup", ram: "12GB", cpus: "6 CPUs", disk: "256 GB SSD disk", price: "$80" },
  { name: "Business", ram: "16GB", cpus: "8 CPUs", disk: "512 GB SSD disk", price: "$160" },
  { name: "Enterprise", ram: "32GB", cpus: "12 CPUs", disk: "1024 GB SSD disk", price: "$240" },
];

const pageDetails = {
  head: {
    upper: "Let’s talk about",
    lower: "YOUR PROJECT",
  },
  bottom: {
    normal: "What are you waiting for?",
    italic: "Let's build your journey together",
  },
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const services = [
  { id: 1, name: "UI/UX Design" },
  { id: 2, name: "Branding" },
  { id: 3, name: "Web Development" },
  { id: 4, name: "ECommerce Development" },
];

export default function Contact() {
  const theme = useContext(ThemeContext);
  const [ui, setUi] = useState(false);
  const [web, setWeb] = useState(false);
  const [brand, setBrand] = useState(false);
  const [ecom, setEcom] = useState(false);
  const [dSys, setDsys] = useState(false);
  const [other, setOther] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [budge, setBudget] = useState("");

  return (
    <>
      <div className="w-screen relative z-20 min-h-screen flex flex-col items-start justify-center">
        <PageHeader title="Contact Us" />
        <InnerHeadr head={pageDetails.head} bottom={pageDetails.bottom} />
        <div className="w-screen">
          <div className="container sticky  top-0 mx-auto flex flex-col">
            <hr className={`w-full h-0 border-t mb-2 ${theme === "dark" ? "border-white" : "border-black"}`} />
          </div>
          <div className="container px-8 mx-auto grid lg:grid-cols-2 grid-cols-1 ">
            <div className="flex col-span-1 lg:sticky h-sixth top-0 ">
              <div className="flex w-full">
                <div className="lg:p-8 flex flex-col">
                  <div className="flex lg:px-32 relative lg:py-16 py-8 flex-col">
                    <svg className="absolute top-20 left-0 transform -translate-x-8 -translate-y-24 lg:h-36 h-12 w-12 lg:w-36 text-org-100 opacity-75" stroke="currentColor" fill="none" viewBox="0 0 144 144" aria-hidden="true">
                      <path
                        strokeWidth={2}
                        d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                      />
                    </svg>
                    <p className="text-xl italic">
                      We’ve been working with Theorem on multiple complex projects now, following an amazingly well-done first marketing website.
                      <br />
                      <br />
                      What really stands out for us is the multi-faceted expertise of the team and their dedication to ensuring our success by going above and beyond at every stage.
                    </p>

                    <div className="flex mt-8">
                      <div className=" w-20 h-20 overflow-hidden ">
                        <img src={EscapeLogo} className="w-full h-full object-cover" />
                      </div>
                      <div className="flex px-10 text-black items-start justify-center flex-col">
                        <p className="text-2xl">M. Sadiq</p>
                        <p>Founder, CEO</p>
                      </div>
                    </div>
                    <hr className="border border-black my-8" />
                    <div className="grid grid-cols-2 gap-16">
                      <div className="col-span-1 flex flex-col">
                        <div className="aspect-square">
                          <img src="https://images.pexels.com/photos/4610272/pexels-photo-4610272.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260" className="w-full h-full object-cover" />
                        </div>
                        <div className="text-xl mt-1 helv font-semibold">Lahore</div>
                        <div className="helv">16th Fl. Tricon Tower</div>
                      </div>
                      <div className="col-span-1 flex flex-col">
                        <div className="aspect-square overflow-hidden">
                          <img src="https://images.pexels.com/photos/3003935/pexels-photo-3003935.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260" className="w-full h-full object-cover" />
                        </div>
                        <div className="text-xl mt-1 helv font-semibold">Austin</div>
                        <div className="helv">East Ben White Boulevard</div>
                      </div>
                    </div>
                    <hr className="border border-black mt-8" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1 mt-16 lg:mt-0 py-20">
              <div className="lg:p-16 p-4 min-h-screen text-white bg-black flex flex-col">
                <div className="">
                  <p className="mb-4 text-xl ">I am interested in ...</p>
                  <div className="flex helv uppercase text-xl flex-wrap">
                    <div onClick={() => setUi(!ui)} className={`service-select ${ui ? " border-primary-100 text-primary-100 opacity-90" : " border-white opacity-75"} `}>
                      UI/UX Design
                    </div>
                    <div onClick={() => setWeb(!web)} className={`service-select ${web ? " border-primary-100 text-primary-100 opacity-90" : " border-white opacity-75"} `}>
                      Website
                    </div>
                    <div onClick={() => setBrand(!brand)} className={`service-select ${brand ? " border-primary-100 text-primary-100 opacity-90" : " border-white opacity-75"} `}>
                      Branding
                    </div>
                    <div onClick={() => setEcom(!ecom)} className={`service-select ${ecom ? " border-primary-100 text-primary-100 opacity-90" : " border-white opacity-75"} `}>
                      ECommerce
                    </div>
                    <div onClick={() => setDsys(!dSys)} className={`service-select ${dSys ? " border-primary-100 text-primary-100 opacity-90" : " border-white opacity-75"} `}>
                      Design System
                    </div>
                    <div onClick={() => setOther(!other)} className={`service-select ${other ? " border-primary-100 text-primary-100 opacity-90" : " border-white opacity-75"} `}>
                      Other
                    </div>
                  </div>
                  <div className="my-8">
                    <div>
                      <label htmlFor="name" className="block  my-4 text-xl text-gray-100">
                        Name
                      </label>
                      <div className="mt-1 border-b border-black focus-within:border-primary-100">
                        <input type="text" name="name" id="name" className="block w-full text-xl uppercase border-0 border-b border-transparent light-white focus:border-primary-100 focus:ring-0" placeholder="Jane Doe" />
                      </div>
                    </div>
                  </div>
                  <div className="my-8">
                    <div>
                      <label htmlFor="email" className="block  my-4 text-xl text-gray-100">
                        Email
                      </label>
                      <div className="mt-1 border-b border-black focus-within:border-primary-100">
                        <input type="text" name="email" id="email" className="block w-full text-xl uppercase border-0 border-b border-transparent light-white focus:border-primary-100 focus:ring-0" placeholder="Jane Doe" />
                      </div>
                    </div>
                  </div>
                  <div className="my-8">
                    <div>
                      <label htmlFor="textare" className="block  my-4 text-xl text-gray-100">
                        Tell Us About Your Project
                      </label>
                      <div className="mt-1 border-b border-black focus-within:border-primary-100">
                        <textarea name="textare" rows="10" id="textare" className="block w-full text-xl capitalize border-0 border-b border-transparent light-white focus:border-primary-100 focus:ring-0" placeholder="Jane Doe"></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="my-8 flex lg:flex-row flex-col items-center justify-between">
                    <div className="relative">
                      <input type="file" name="upload" className="opacity-0 relative z-10 w-full h-full cursor-pointer" accept=".jpeg .png .jpg .webp .pdf" />
                      <label htmlFor="upload" className="flex absolute inset-0 cursor-pointer">
                        <div className="flex  items-center justify-start">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M14 13.5V8a4 4 0 1 0-8 0v5.5a6.5 6.5 0 1 0 13 0V4h2v9.5a8.5 8.5 0 1 1-17 0V8a6 6 0 1 1 12 0v5.5a3.5 3.5 0 0 1-7 0V8h2v5.5a1.5 1.5 0 0 0 3 0z" fill="rgba(255,255,255,1)" />
                          </svg>
                          <div className="ml-2 cursor-pointer">Attach File</div>
                        </div>
                      </label>
                    </div>
                    <div className="flex lg:items-end items-start w-full lg:mt-0 mt-8">
                      <button className="bg-primary-100 py-4 px-8">Send Details</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-screen my-20 relative"></div>
      </div>
    </>
  );
}

// <div className="pt-20 ">
//   <div className="flex h-screen items-center justify-start my-16 flex-col">
//     <p className="text-2xl px-32">We pride ourselves in being fully transparent. We are clear on every step we take, and you will always be involved in all business decisions.</p>
//     <div className="my-8 bg-white p-32 w-full">
//       <fieldset className="w-full">
//         <legend className="text-lg font-medium text-gray-900">Services</legend>
//         <div className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
//           {services.map((person, personIdx) => (
//             <div key={personIdx} className="relative flex items-start py-4">
//               <div className="min-w-0 flex-1 text-sm">
//                 <label htmlFor={`person-${person.id}`} className="font-medium text-gray-700 select-none">
//                   {person.name}
//                 </label>
//               </div>
//               <div className="ml-3 flex items-center h-5">
//                 <input id={`person-${person.id}`} name={`person-${person.id}`} type="checkbox" className="focus:ring-primary-100 h-4 w-4 text-primary-100 border-gray-300 rounded" />
//               </div>
//             </div>
//           ))}
//         </div>
//       </fieldset>
//       <RadioGroup value={budge} onChange={setBudget}>
//         <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
//         <div className="space-y-4">
//           {plans.map((plan) => (
//             <RadioGroup.Option
//               key={plan.name}
//               value={plan}
//               className={({ checked, active }) =>
//                 classNames(
//                   checked ? "border-transparent" : "border-gray-300",
//                   active ? "ring-2 ring-primary-100" : "",
//                   "relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none"
//                 )
//               }
//             >
//               {({ active, checked }) => (
//                 <>
//                   <div className="flex items-center">
//                     <div className="text-sm">
//                       <RadioGroup.Label as="p" className="font-medium text-gray-900">
//                         {plan.name}
//                       </RadioGroup.Label>
//                       <RadioGroup.Description as="div" className="text-gray-500">
//                         <p className="sm:inline">
//                           {plan.ram} / {plan.cpus}
//                         </p>{" "}
//                         <span className="hidden sm:inline sm:mx-1" aria-hidden="true">
//                           &middot;
//                         </span>{" "}
//                         <p className="sm:inline">{plan.disk}</p>
//                       </RadioGroup.Description>
//                     </div>
//                   </div>
//                   <RadioGroup.Description as="div" className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
//                     <div className="font-medium text-gray-900">{plan.price}</div>
//                     <div className="ml-1 text-gray-500 sm:ml-0">/mo</div>
//                   </RadioGroup.Description>
//                   <div className={classNames(active ? "border" : "border-2", checked ? "border-primary-100" : "border-transparent", "absolute -inset-px rounded-lg pointer-events-none")} aria-hidden="true" />
//                 </>
//               )}
//             </RadioGroup.Option>
//           ))}
//         </div>
//       </RadioGroup>
//     </div>
//   </div>
// </div>;
